import "core-js/modules/es.array.for-each";
import "core-js/modules/web.dom-collections.for-each";
import HeroSlider from './sliders/HeroSlider';
import Marquee from './animations/Marquee';
import MagneticToCursor from './animations/MagneticToCursor';
import { initSliders, StandardSwipe } from './sliders';
var swipeSliders = [];
var sliderMagnets = [];

var initiateProductSlider = function initiateProductSlider() {
  var productSlider = document.querySelector('.product-slider');

  if (productSlider) {
    var newProductSlider = new StandardSwipe(productSlider, {
      loop: true,
      slidesPerView: 'auto',
      centeredSlides: true,
      spaceBetween: 20,
      speed: 800,
      autoplay: {
        delay: 4000
      },
      pagination: {
        el: productSlider.querySelector('.swiper-pagination'),
        clickable: true
      },
      breakpoints: {
        1024: {
          spaceBetween: 40
        }
      }
    });
    newProductSlider.standardSwiper.on('init', function () {
      var cards = productSlider.querySelectorAll('.product-slider__image');
      var startingBreakpoint = 1024;
      cards.forEach(function (card) {
        var newMagnet = new MagneticToCursor(card, startingBreakpoint);
        newMagnet.init();
        sliderMagnets.push(newMagnet);
      });
    });
    newProductSlider.standardSwiper.on('slideChangeTransitionEnd', function () {
      sliderMagnets.forEach(function (magnet) {
        return magnet.init();
      });
    });
    swipeSliders.push(newProductSlider);
  }
}; // Hero slider


var initiateHeroSlider = function initiateHeroSlider() {
  var heroElement = document.querySelector('#heroCarousel');

  if (heroElement) {
    var heroSlider = new HeroSlider(heroElement);
    heroSlider.init();
  }
}; // Marquees


var allMarquees = [];

var initiateMarquee = function initiateMarquee() {
  var marquees = document.querySelectorAll('.marquee');

  if (marquees.length > 0) {
    marquees.forEach(function (marquee) {
      var items = marquee.querySelectorAll('.background-heading');
      var newMarquee = new Marquee(marquee, items);
      newMarquee.init();
      allMarquees.push(newMarquee);
    });
  }
};

window.addEventListener('DOMContentLoaded', function () {
  initiateHeroSlider();
  initiateMarquee();
  initiateProductSlider();
  initSliders(swipeSliders);
});
window.addEventListener('resize', function () {
  if (allMarquees.length > 0) {
    allMarquees.forEach(function (marquee) {
      marquee.init();
    });
  }

  if (sliderMagnets.length > 0) sliderMagnets.forEach(function (magnet) {
    return magnet.init();
  });
});
window.addEventListener('scroll', function () {
  if (sliderMagnets.length > 0) sliderMagnets.forEach(function (magnet) {
    return magnet.init();
  });
}, {
  passive: true
});