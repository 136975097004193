import "core-js/modules/es.array.for-each";
import "core-js/modules/web.dom-collections.for-each";

/**
 * Function to initiate an array of sliders and handle responsive enabling/disabling
 *
 * @param {Array} slickSliders
 */
export default (function (swiperSliders) {
  swiperSliders.forEach(function (slider) {
    if (slider.sliderElement) {
      slider.init();
    } else {
      console.warn('Slider creation failed: ', slider);
    }
  });
});