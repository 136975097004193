/**
 * Default configuration for swiper sliders
 **/
export default {
  init: false,
  loop: false,
  speed: 500,
  autoplay: false,
  slideNextClass: 'swiper-slide-next',
  navigation: {
    prevEl: '.swiper-button-prev',
    nextEl: '.swiper-button-next'
  }
};